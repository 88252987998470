<template>
  <div class="row">
    <div class="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
      <div class="d-flex align-items-center justify-content-between">
        <h4>類別</h4>
      </div>
      <div class="create-workform">
        <button type="button" class="btn btn-primary position-relative d-flex align-items-center justify-content-between" data-bs-toggle="modal" data-bs-target="#modalcal">
          <svg xmlns="http://www.w3.org/2000/svg" class="me-2" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
          </svg>
          新增
        </button>
      </div>
    </div>
    <div class="col-sm-12" v-for="(item, index) in friendRequest" :key="index">
      <iq-card>
        <template v-slot:headerTitle>
          <h4 class="card-title">{{ item.title }}</h4>
        </template>
        <template v-slot:headerAction>
          <div class="d-flex align-items-center">
            <div class="dropdown">
              <tooltip tag="span" type="" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" tooltipPlacement="top" :data-bs-original-title="$t('label.displayMore')"><i
                  class="ri-more-fill h4"></i>
              </tooltip>
              <div class="dropdown-menu dropdown-menu-right" style="">
                <a class="dropdown-item" href="#"><i class="ri-thumb-up-line me-2"></i>{{$t('label.mostLikes')}}</a><a class="dropdown-item" href="#"><i
                    class="ri-money-dollar-circle-line me-2"></i>{{$t('label.maximumRewardAmount')}}</a><a class="dropdown-item" href="#"><i class="ri-sort-asc me-2"></i>{{$t('label.ascendingOrder')}}</a><a
                  class="dropdown-item" href="#"><i class="ri-sort-desc me-2"></i>{{$t('label.descendingOrder')}}</a>
              </div>
            </div>
          </div>
        </template>
        <template v-slot:body>
          <ul class="request-list list-inline m-0 p-0">
            <li class="d-flex align-items-center justify-content-between flex-wrap" v-for="(list, index1) in item.list" :key="index1">
              <div class="flex-grow-1 ms-3">
                <h4>{{ list.name }}</h4>
                <div class="d-flex feather-icon mt-2 mt-md-0">
                  <i class="ri-image-line"></i><span class="ms-1 me-1">0</span>
                  <i class="ri-video-line"></i><span class="ms-1 me-1">0</span>
                </div>
                <div class="d-flex feather-icon mt-2 mt-md-0">
                  <span class="ms-1 me-1">空的</span>
                </div>

                <span class="mb-0"></span>
              </div>
              <div class="d-flex align-items-center mt-2 mt-md-0">
                <div class="confirm-click-btn">
                  <div class="iq-media-group">
                    <a href="#" class="iq-media"><img class="img-fluid avatar-40 img-fluid rounded" src="/img/05.50518705.jpg" alt="" /></a><a href="#" class="iq-media"><img class="img-fluid avatar-40 img-fluid rounded"
                        src="/img/06.82085e4a.jpg" alt="" /></a>
                  </div>
                </div>
              </div>
            </li>
            <li class="d-block text-center mb-0 pb-0">
              <a href="#" class="me-3 btn">檢視更多</a>
            </li>
          </ul>
        </template>
      </iq-card>
    </div>
  </div>
  <modal id="modalcal" tabindex="-1" title="新增貼文" aria-labelledby="modalcalLabel" aria-hidden="true">
    <model-header>
      <h5 class="modal-title" id="modalcalLabel">新增新類別</h5>
      <button :dismissable="true" type="button" class="btn-close" data-bs-dismiss="modal"></button>
    </model-header>
    <model-body>
      <div class="row">
        <div class="col-md-12 mb-3">
          <label for="Text1" class="form-label font-weight-bold text-muted text-uppercase">類別名稱</label>
          <input type="text" class="form-control" id="Text1" placeholder="輸入類別名稱" />
        </div>
      </div>
    </model-body>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
        取消
      </button>
      <button type="button" class="btn btn-primary">保存</button>
    </div>
  </modal>
</template>
<script>
import { socialvue } from '../../../config/pluginInit'
export default {
  name: 'FriendRequest',
  mounted() {
    socialvue.index()
  },
  data() {
    return {
      friendRequest: [
        {
          title: '自定義類別',
          btn1: 'Confirm',
          btn2: 'Delete Request',
          list: [
            {
              img: require('../../../assets/images/user/05.jpg'),
              name: 'Posts',
              friend: '2 人物 73 貼子'
            },
            {
              img: require('../../../assets/images/user/06.jpg'),
              name: 'Messages',
              friend: '空的'
            }
          ]
        }
      ]
    }
  },
  methods: {
    deleterequest() {
      const swalWithBootstrapButtons = this.$swal.mixin({
        customClass: {
          confirmButton: 'btn btn-primary btn-lg',
          cancelButton: 'btn btn-outline-primary btn-lg ms-2'
        },
        buttonsStyling: false
      })

      swalWithBootstrapButtons
        .fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          showClass: {
            popup: 'animate__animated animate__zoomIn'
          },
          hideClass: {
            popup: 'animate__animated animate__zoomOut'
          }
        })
        .then(willDelete => {
          if (willDelete.isConfirmed) {
            swalWithBootstrapButtons.fire({
              title: 'Deleted!',
              text: 'Your Request has been deleted.',
              icon: 'success',
              showClass: {
                popup: 'animate__animated animate__zoomIn'
              },
              hideClass: {
                popup: 'animate__animated animate__zoomOut'
              }
            })
          } else {
            swalWithBootstrapButtons.fire({
              title: 'Your Request is safe!',
              showClass: {
                popup: 'animate__animated animate__zoomIn'
              },
              hideClass: {
                popup: 'animate__animated animate__zoomOut'
              }
            })
          }
        })
    }
  }
}
</script>
